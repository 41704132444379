import React from 'react';
import { FaExternalLinkSquareAlt } from "react-icons/fa";
import { v4 as uuidv4 } from 'uuid';
import { useSystemContext } from '../Context/SystemContext';
import Grid from '../components/Grid';
import { dateToString } from '../helper/dates';

export function LicitaDashboard() {
    const System = useSystemContext();
    return (
        <div className="w-full bg-white">
            <Grid
                url={'/licitaDashboard'}
                hideIndexColumn={true}
                preventAutoEdit={true}
                canEdit={false}
                canDelete={false}
                defaultSortField="@quando"
                defaultItemsPerPage={20}
                showSearch={true}
                canInsert={false}
                titlePDF={'Dashboard'}
                showPDFExport={false}
                showCSVExport={true}
                GridHeaders={[
                    { title: 'Nome', field: 'nome', sort: true, className: 'w-6/12 pl-1 text-center border-l border-gray-300' },
                    { title: 'O que', field: 'oque', sort: true, className: 'w-5/12 pl-1 text-center border-l border-gray-300' },
                    { title: 'Quando', field: 'quando', sort: true, type: 'date', className: 'w-1/12 pl-1 text-center border-l border-gray-300' },
                    { title: 'Atalho', sort: false, className: 'w-min pl-1 text-center border-l border-gray-300' },
                ]}
                hideActionsButtons={true}
                LineDataPrint={(item) => {
                    let _dt = dateToString(item.quando);
                    let dateObject = new Date(item.quando);
                    dateObject.setDate(dateObject.getDate() + 1);
                    let _vencido = dateObject < new Date();
                    return [
                        item.nome,
                        item.oque,
                        _vencido ? `#color=red:${_dt}` : _dt,
                        <div to={item.link} onClick={() => {
                            System.setAutoEdit(item.id);
                            window.location.href = `/database/${item.menu}`; // Adicionado para mudar a janela atual
                        }
                        }>
                            <FaExternalLinkSquareAlt className="w-full mt-1 cursor-pointer hover:text-blue-800" title="Acessar o item" key={uuidv4()} />
                        </div>,
                    ];
                }}
            />
        </div>
    );
}
