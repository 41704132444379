import React, { useState } from 'react';
import { BiCaretDown, BiCaretUp, BiMenu } from 'react-icons/bi';
import { v4 as uuidv4 } from 'uuid';
import Layout from '../Layout/Layout';
import { Form41 } from './forms/Form_4';

import { useSystemContext } from '../Context/SystemContext';
import { LicitaBusca } from './LicitaBusca';
import { LicitaChart } from './LicitaChart';
import { LicitaDashboard } from './LicitaDashboard';
import { LicitaEdital } from './LicitaEdital';
import { LicitaOperacao } from './LicitaOperacao';
import { LicitaPadrao } from './LicitaPadrao';

const DatabasePage = (props) => {
    const System = useSystemContext();
    const [Menu, setMainMenu] = useState(parseInt(props.defaultMenu));
    const [menuOpen, setMenuOpen] = useState(0);
    function MenuDatabase() {
        function setMenu(item) {
            if (System.property.user.level <= 1) {
                if (!System.methods.getUserRule('menu' + item)) {
                    System.methods.notificationError('Seu nível não tem acesso a esta área!');
                    return;
                }
            }
            setMainMenu(item);
        }
        function renderMainMenu(data) {
            function renderSubMenu(item) {
                if (item.length === 0) {
                    return null;
                }
                return (
                    <div>
                        {item.map((entry) => {
                            const key = Object.keys(entry)[0];
                            const value = entry[key];
                            let subitem = false;
                            if (entry.hasOwnProperty('sub')) {
                                subitem = entry.sub;
                            }
                            return (
                                <div
                                    key={uuidv4()}
                                    onClick={() => setMenu(parseInt(key))}
                                    className={`pl-4 hover:bg-gray-300 ${subitem ? 'ml-2' : null} ${Menu === parseInt(key) ? 'bg-gray-200' : 'bg-white'} `}
                                >
                                    {value}
                                </div>
                            );
                        })}
                    </div>
                );
            }
            function renderMenu(key, value) {
                let clickSelf = null;
                if (value.hasOwnProperty('self')) {
                    clickSelf = value.self;
                }
                return (
                    <div key={uuidv4()} className="pl-2 text-left bg-white border-b border-l border-r">
                        <div className={`w-full cursor-pointer`}>
                            <div className="inline-flex hover:bg-gray-300" onClick={!clickSelf ? () => setMenuOpen(key) : () => setMenu(clickSelf)}>
                                {value.menu} {menuOpen === key ? <BiCaretUp className="mt-1" /> : <BiCaretDown className={`mt-1 ${clickSelf ? 'invisible' : 'visible'}`} />}
                            </div>
                            {menuOpen === key && <>{renderSubMenu(value.submenu)}</>}
                        </div>
                    </div>
                );
            }
            return (
                <div className="pl-2 text-left bg-white border-b border-l border-r">
                    <div className={`w-full cursor-pointer`}>{Object.entries(data).map(([key, value]) => renderMenu(key, value))}</div>
                </div>
            );
        }

        return (
            <div className="pt-4 overflow-y-auto rounded h-4/6 w-72">
                <div className="inline-flex w-full pl-2 text-xl rounded-t h-7 bg-amarelo-eq">
                    <BiMenu className="mt-1 mr-1" /> Opções
                </div>
                {renderMainMenu(companyMenu())}
            </div>
        );
    }
    return (
        <Layout>
            <div className="h-full ml-2 text-sm">{MenuDatabase()}</div>
            <div className="w-2 bg-yellow-50"></div>
            <div className="w-full h-full p-2 pt-2 bg-yellow-50">
                {Menu > 0 && (
                    <div className="mt-2 bg-white border border-gray-300 rounded-t shadow">
                        {Menu === 5 && <LicitaDashboard />}
                        {Menu === 10 && <LicitaEdital />}
                        {Menu === 30 && <LicitaPadrao />}
                        {Menu === 40 && <LicitaBusca />}
                        {Menu === 50 && <LicitaOperacao />}

                        {Menu === 90 && <LicitaChart />}

                        {Menu === 99 && <Form41 />}
                    </div>
                )}
            </div>
        </Layout>
    );
};

export function companyMenu() {
    let data = {
        5: {
            menu: 'Dashboard',
            self: 5,
            submenu: [
            ],
        },
        10: {
            menu: 'Cadastro de Editais',
            self: 10,
            submenu: [
            ],
        },
        20: {
            menu: 'Cadastro controle',
            submenu: [
                { 30: 'Documento Padrão' },
                { 40: 'Plataforma de Pesquisa' },
                { 50: 'Plataforma de Operação' }
            ]
        },
        90: {
            menu: 'Indicadores',
            self: 90,
            submenu: [],
        }
    };
    return data;
}

export default DatabasePage;
