import { dateToString } from '../helper/dates';
import Grid from './Grid';
import { DateInput, Input } from './Input';

const yup = require('../interface/yup-br');

export const GridDiario = ({ url, GridHeaders, LineDataPrint, GridTitle = 'Diário de Bordo', ...rest }) => {
    let _GridHeaders = [
        { title: 'Data', field: 'data', sort: true, className: 'w-1/6 text-left pl-1' },
        { title: 'Registro', field: 'registro', sort: true, className: 'w-5/6 text-left pl-1' },
    ];
    let _LineDataPrint = (data) => {
        return [dateToString(data?.data), data.registro];
    };
    return (
        <Grid
            url={url}
            {...rest}
            disableModal={true}
            GridHeaders={GridHeaders ?? _GridHeaders}
            GridTitle={GridTitle}
            LineDataPrint={LineDataPrint ?? _LineDataPrint}
            onRenderForm={() => {
                return (
                    <div className="w-full">
                        <DateInput name="data" label="Data" className={"w-min"} />
                        <Input name="registro" label="Registro" />
                    </div>
                );
            }}
            validationSchema={() => {
                return yup.object().shape({
                    data: yup.string(),
                    registro: yup.string(),
                });
            }}
        />
    );
};
