import React, { Fragment } from 'react';
import Grid from '../components/Grid';
import { AttachmentInput, DateInput, GroupInput, Input, SelectInput, TextInput } from '../components/Input';
import { dateToString } from '../helper/dates';

const yup = require('../interface/yup-br');

export const LicitaPadrao = () => {
    return (
        <div className="m-4 mx-2 ">
            <Grid
                url={'/licitapadrao'}
                perms={'30'}
                disableModal={true}
                titlePDF="Documentos padrão"
                GridHeaders={[
                    { title: 'Descrição', field: 'descricao', sort: true, className: 'w-9/12' },
                    { title: 'Data', field: 'data', type: 'date', sort: true, className: 'w-1/12' },
                    { title: 'Vencimento', field: 'vencimento', type: 'date', sort: true, className: 'w-1/12' },
                    { title: 'Status', field: 'ativo', sort: true, className: 'w-1/12 text-left pl-1' },
                ]}
                LineDataPrint={(data) => {
                    return [
                        data.descricao,
                        dateToString(data.data),
                        dateToString(data.vencimento),
                        data.ativo ? '🟢 Ativo' : '🔴 Inativo',
                    ];
                }}
                onRenderForm={(GridModes, SetFieldValue, values) => {
                    localStorage.setItem('autoEdit', null);
                    return (
                        <Fragment>
                            <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                            </div>
                            <GroupInput>
                                <Input name="descricao" label="Descrição" className="w-1/2" />
                                <DateInput name="data" label="Data" className="w-min" />
                                <DateInput name="vencimento" label="Vencimento" className="w-min" />
                                <SelectInput
                                    name="ativo"
                                    label="Status"
                                    listOptions={[
                                        { option: 'Ativo', value: true },
                                        { option: 'Inativo', value: false },
                                    ]}
                                    className={'w-48'}
                                />

                            </GroupInput>
                            <GroupInput>
                                <TextInput name="detalhamento" label="Detalhamento" className="w-full" rows="4" />
                            </GroupInput>
                            <GroupInput>
                                <TextInput name="acaoimediata" label="Ação imediata" className="w-full" rows="4" />
                            </GroupInput>
                            <AttachmentInput name="anexo" label="Anexo" />
                        </Fragment>
                    );
                }}
                validationSchema={
                    yup.object().shape({
                        descricao: yup.string().required('Informe a descrição'),
                        data: yup.date().required('Data inválida'),
                        vencimento: yup.date().required('Vencimento inválido'),
                    })
                }
            />
        </div >

    );
}