import React from 'react';
import Grid from '../components/Grid';
import { DateInput, GroupInput, Input, SelectInput } from '../components/Input';
import { dateToString } from '../helper/dates';

const yup = require('../interface/yup-br');

export const LicitaBusca = () => {
    return (
        <div className="m-4 mx-2 ">
            <div className="mt-4 ml-2 bg-white grow">
                <div className="w-full border shadow">
                    <Grid
                        url={'/licitabusca'}
                        disableModal={true}
                        GridHeaders={[
                            { title: 'Nome', field: 'nome', sort: true, className: 'w-10/12' },
                            { title: 'Vencimento', field: 'vencimento', type: 'date', sort: true, className: 'w-1/12' },
                            { title: 'Ativo', field: 'ativo', sort: true, className: 'w-1/12 text-left pl-1' },
                        ]}
                        GridTitle="Plataformas de busca"
                        LineDataPrint={(data) => {
                            return [
                                data.nome,
                                dateToString(data.vencimento),
                                data.ativo ? '🟢 Ativo' : '🔴 Inativo',
                            ];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            localStorage.setItem('autoEdit', null);
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <GroupInput>
                                        <Input name="nome" label="Nome" />
                                        <Input name="url" label="URL" />
                                    </GroupInput>
                                    <GroupInput>
                                        <DateInput name="vencimento" label="Vencimento" className={'w-min'} />
                                        <SelectInput
                                            name="ativo"
                                            label="Status"
                                            listOptions={[
                                                { option: 'Ativo', value: true },
                                                { option: 'Inativo', value: false },
                                            ]}
                                            className={'w-48'}
                                        />
                                    </GroupInput>
                                </div>
                            );
                        }}
                        validationSchema={yup.object().shape({
                            nome: yup.string().required('Nome é obrigatório'),
                            vencimento: yup.date().required('Vencimento inválido'),
                        })}
                    />
                </div>
            </div>

        </div >

    );
}